
import {
  defineComponent, reactive, watch, onBeforeMount,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import ColunaGrade from '@/core/components/Tela/ColunaGrade.vue';
import SelecionarNcm from '@/components/Cadastros/Produtos/SelecionarNcm.vue';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import ApresentarCfop from '../../Cfops/ApresentarCfop.vue';
import { useGradeBase } from '@/core/composables/GradeBase';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import CenarioFiscalDefinicaoIcmsModal from './CenarioFiscalDefinicaoIcmsModal.vue';
import CenarioFiscalCopiarDefinicaoTributariaModal from '../CenarioFiscalCopiarDefinicaoTributariaModal.vue';
import { ETipoItemCenarioFiscal } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoItemCenarioFiscal';
import storeCenarioFiscal from '@/store/Cadastros/Tributacoes/storeCenarioFiscal';
import { ICenarioFiscalDefinicao, ICenarioFiscalDefinicaoIcmsTipoAtividade } from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import { IParametrosConsultaDefinicao } from '@/models/ParametrosRequisicao/Cadastros/Tributacoes/IParametrosConsultaDefinicao';
import { ETipoImposto } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoImposto';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOCenarioFiscalCopiarDefinicoes } from '@/models/DTO/Cadastros/Tributacoes/CenariosFiscais/IDTOCenarioFiscalCopiarDefinicoes';

export default defineComponent({
  name: 'CenarioFiscalDefinicoesIcmsEstadoEspecificoModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icone,
    Card,
    RequisicaoModal,
    MensagemSemDados,
    ColunaGrade,
    CenarioFiscalDefinicaoIcmsModal,
    CenarioFiscalCopiarDefinicaoTributariaModal,
    SelecionarNcm,
    SelecionarProduto,
    ApresentarCfop,
  },
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso } = useTelaBase();
    const {
      modalBase, apresentarBarraProgresso, ocultarBarraProgresso, apresentarRetornoRequisicao,
    } = useModalBase(props, emit);
    const { gradeBase } = useGradeBase();
    const servicoCenarioFiscal = new ServicoCenarioFiscal();
    const servicoPessoa = new ServicoPessoa();

    const state = reactive({
      tipoItemSelecionado: {} as ETipoItemCenarioFiscal,
      codigoItemSelecionado: 0,
      bloquearEdicao: false,
      definicoes: [] as ICenarioFiscalDefinicao[],
      definicoesSelecionadas: [] as ICenarioFiscalDefinicao[],
      origensMercadorias: [] as IItemGenerico[],
      tiposAtividades: [] as IItemGenerico[],
      apresentarDefinicoesIcms: false,
      apresentarCopiarDefinicoes: false,
      codigoDefinicao: 0,
      duplicarDefinicao: false,
    });

    onBeforeMount(async () => {
      state.origensMercadorias = await servicoCenarioFiscal.obterListaOrigemMercadorias();
      state.tiposAtividades = await servicoPessoa.obterListaTipoAtividade();
    });

    gradeBase.colunas = [
      {
        title: 'Ações', key: 'acoes', position: 1, visible: true, align: 'left', fixed: 'left', width: 100,
      },
      {
        title: 'Origem Mercadoria', dataIndex: 'origemMercadoriaDefinicaoIcms', key: 'OrigemMercadoriaDefinicaoIcms', align: 'left', position: 2, visible: true, ellipsis: true,
      },
      {
        title: 'Tipo Atividade', dataIndex: 'tipoAtividadeDefinicaoIcms', key: 'TipoAtividadeDefinicaoIcms', align: 'left', position: 3, visible: true, ellipsis: true,
      },
      {
        title: 'CFOP', dataIndex: 'cfopDefinicaoIcms', key: 'CfopDefinicaoIcms', align: 'center', position: 4, visible: true, width: 100,
      },
      {
        title: 'CST/CSOSN', dataIndex: 'cstDefinicaoIcms', key: 'CstDefinicaoIcms', align: 'center', position: 5, visible: true, width: 100,
      },
      {
        title: 'ICMS %', dataIndex: 'icmsDefinicaoIcms', key: 'IcmsDefinicaoIcms', align: 'right', position: 6, visible: true, width: 80,
      },
      {
        title: 'Red.ICMS', dataIndex: 'redIcmsDefinicaoIcms', key: 'RedIcmsDefinicaoIcms', align: 'right', position: 7, visible: true, width: 80,
      },
      {
        title: 'ICMS Subst %', dataIndex: 'icmsSubstDefinicaoIcms', key: 'IcmsSubstDefinicaoIcms', align: 'right', position: 8, visible: true, width: 120,
      },
      {
        title: 'Red.Subst', dataIndex: 'redSubstDefinicaoIcms', key: 'RedSubstDefinicaoIcms', align: 'right', position: 9, visible: true, width: 80,
      },
      {
        title: 'MVA', dataIndex: 'mvaDefinicaoIcms', key: 'MvaDefinicaoIcms', align: 'right', position: 10, visible: true, width: 80,
      },
      {
        title: 'Código', dataIndex: 'codigo', key: 'CodigoDefinicao', position: 11, visible: false, ordering: true,
      },
    ];

    async function buscarDados() {
      state.definicoes = [];

      const parametrosConsulta = {} as IParametrosConsultaDefinicao;
      parametrosConsulta.tipoImposto = ETipoImposto.Icms;
      parametrosConsulta.tipoItem = storeCenarioFiscal.state.tipoItemSelecionado;
      parametrosConsulta.codigoItemSelecionado = storeCenarioFiscal.state.codigoItemSelecionado;
      parametrosConsulta.estado = storeCenarioFiscal.state.estadoSelecionado.codigo;
      parametrosConsulta.buscaResumida = false;

      gradeBase.buscandoDados = true;

      const listaDefinicoes = await servicoCenarioFiscal.obterDefinicoes(storeCenarioFiscal.getters.codigo(), parametrosConsulta);
      if (listaDefinicoes !== undefined && listaDefinicoes !== null) {
        state.definicoes = listaDefinicoes;
      }

      gradeBase.buscandoDados = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      gradeBase.codigosSelecionados = [];
      if (modalBase.computedVisivel) {
        if (UtilitarioGeral.validaCodigo(storeCenarioFiscal.state.codigoItemSelecionado)) {
          state.codigoItemSelecionado = storeCenarioFiscal.state.codigoItemSelecionado;
        } else {
          state.codigoItemSelecionado = 0;
        }

        if (storeCenarioFiscal.state.codigoItemSelecionado > 0) {
          await buscarDados();
        }
      }
      telaBase.carregando = false;
    });

    function preparaMensagemGrade():string {
      if (gradeBase.buscandoDados) {
        return 'Buscando informações, por favor aguarde...';
      }

      return 'Adicione a sua definição tributária de ICMS';
    }

    function apresentarModalDefinicaoIcms(codigoDefinicao: number, duplicar:boolean) {
      state.codigoDefinicao = codigoDefinicao;
      state.duplicarDefinicao = duplicar;
      state.apresentarDefinicoesIcms = true;
    }

    function montaApresentacaoOrigem(origemMercadoria:number) {
      let apresentacaoOrigem = '';
      if (UtilitarioGeral.validaLista(state.origensMercadorias)) {
        apresentacaoOrigem += state.origensMercadorias.find((c) => c.identificador === origemMercadoria.toString())?.descricao;
      }

      return apresentacaoOrigem;
    }

    function montaApresentacaoAtividades(tipoAtividades: ICenarioFiscalDefinicaoIcmsTipoAtividade[]) {
      let apresentacaoTipoAtividade = '';

      if (UtilitarioGeral.validaLista(tipoAtividades) && UtilitarioGeral.validaLista(state.tiposAtividades)) {
        tipoAtividades.forEach((itemTipoAtividade) => {
          if (UtilitarioGeral.valorValido(apresentacaoTipoAtividade)) {
            apresentacaoTipoAtividade += ', ';
          }
          apresentacaoTipoAtividade += state.tiposAtividades.find((c) => c.identificador === itemTipoAtividade.tipoAtividade.toString())?.descricao;
        });
      }

      return apresentacaoTipoAtividade;
    }

    function atualizarDefinicao(definicao: ICenarioFiscalDefinicao) {
      const index = state.definicoes.findIndex((c) => c.codigo === definicao.codigo);
      if (index >= 0) {
        state.definicoes[index] = definicao;
      } else {
        state.definicoes.push(definicao);
      }
    }

    function excluirDefinicao(definicao: ICenarioFiscalDefinicao) {
      const index = state.definicoes.findIndex((c) => c.codigo === definicao.codigo);
      if (index >= 0) {
        state.definicoes.splice(index, 1);
      }
    }

    async function copiarDefinicaoTributariaEstados(estadosSemDefinicoes:boolean) {
      state.definicoesSelecionadas = [];
      if (!UtilitarioGeral.validaLista(gradeBase.codigosSelecionados)) {
        apresentarMensagemAlerta('Nenhuma definição foi selecionada!');
        return;
      }

      const objeto = {} as IDTOCenarioFiscalCopiarDefinicoes;
      objeto.tipoImposto = ETipoImposto.Icms;
      objeto.codigoCenarioFiscal = storeCenarioFiscal.getters.codigo();
      objeto.codigoEmpresa = storeCenarioFiscal.getters.codigoEmpresa();
      objeto.itensSelecionados = [{ tipoItem: storeCenarioFiscal.state.tipoItemSelecionado, codigoSelecionado: storeCenarioFiscal.state.codigoItemSelecionado }];
      objeto.definicoesTributarias = [];
      objeto.todosEstados = !estadosSemDefinicoes;
      objeto.estadosSemDefinicoes = estadosSemDefinicoes;

      gradeBase.codigosSelecionados.forEach((codigoDefinicao) => {
        const definicaoTributaria = state.definicoes.find((c) => c.codigo === codigoDefinicao);
        if (definicaoTributaria !== undefined) {
          objeto.definicoesTributarias.push(definicaoTributaria);
        }
      });

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso('Aguarde por favor... Estamos copiando as suas definições tributárias.');

      retorno = await servicoCenarioFiscal.copiarDefinicoes(objeto);

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        storeCenarioFiscal.mutations.atualizarRecarregarResumoIcms(true);
        apresentarMensagemSucesso(retorno.mensagem);
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    function copiarDefinicaoTributaria() {
      state.definicoesSelecionadas = [];
      if (!UtilitarioGeral.validaLista(gradeBase.codigosSelecionados)) {
        apresentarMensagemAlerta('Nenhuma definição foi selecionada!');
        return;
      }

      const definicoesSelecionadas:ICenarioFiscalDefinicao[] = [];

      gradeBase.codigosSelecionados.forEach((codigoDefinicao) => {
        const definicaoTributaria = state.definicoes.find((c) => c.codigo === codigoDefinicao);
        if (definicaoTributaria !== undefined) {
          definicoesSelecionadas.push(definicaoTributaria);
        }
      });

      state.definicoesSelecionadas = definicoesSelecionadas;
      state.apresentarCopiarDefinicoes = true;
    }

    async function excluirDefinicoes(codigosDefinicoes:number[]) {
      const retorno = await servicoCenarioFiscal.excluirDefinicao(storeCenarioFiscal.getters.codigoEmpresa(), codigosDefinicoes);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        codigosDefinicoes.forEach((codigoDefinicao) => {
          const index = state.definicoes.findIndex((c) => c.codigo === codigoDefinicao);
          if (index >= 0) {
            state.definicoes.splice(index, 1);
          }
        });
        apresentarMensagemSucesso(retorno.mensagem);
        storeCenarioFiscal.mutations.atualizarRecarregarResumoIcms(true);
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta('Não foi possível concluir a exclusão!');
      }
    }

    async function confirmaExclusao(codigosDefinicoes:number[]) {
      Modal.confirm({
        title: 'Atenção',
        content: codigosDefinicoes.length === 1 ? 'Você confirma a exclusão dessa definição tributária de ICMS?' : 'Você confirma a exclusão dessas definições tributárias de ICMS?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await excluirDefinicoes(codigosDefinicoes); },
      });
    }

    const onSelectChange = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
    };
    return {
      telaBase,
      modalBase,
      gradeBase,
      props,
      state,
      ETipoItemCenarioFiscal,
      ETipoImposto,
      storeCenarioFiscal,
      UtilitarioGeral,
      onSelectChange,
      UtilitarioMascara,
      preparaMensagemGrade,
      apresentarModalDefinicaoIcms,
      buscarDados,
      montaApresentacaoOrigem,
      montaApresentacaoAtividades,
      atualizarDefinicao,
      excluirDefinicao,
      copiarDefinicaoTributaria,
      copiarDefinicaoTributariaEstados,
      confirmaExclusao,
    };
  },
});
