
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BotaoInformativo',
  props: {
    titulo: {
      type: String,
    },
    informacoes: {
      type: String,
    },
  },
  setup(props) {
    return {
      props,
    };
  },
});
