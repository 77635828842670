import { reactive, readonly } from 'vue';
import { IDTODadosCenarioFiscal } from '@/models/DTO/Cadastros/Tributacoes/CenariosFiscais/IDTODadosCenarioFiscal';
import { ETipoItemCenarioFiscal } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoItemCenarioFiscal';
import { IDTOEstado } from '@/models/DTO/Cadastros/Localizacoes/IDTOEstado';

const state = reactive({
  dadosCenarioFiscal: {} as IDTODadosCenarioFiscal,
  tipoItemSelecionado: {} as ETipoItemCenarioFiscal,
  codigoItemSelecionado: 0,
  estadoSelecionado: {} as IDTOEstado,
  filtrarApenasDadosEntradas: false,
  filtrarApenasDadosSaidas: false,
  recarregarResumoIcms: false,
  recarregarResumoIpi: false,
  recarregarResumoPis: false,
  recarregarResumoCofins: false,
});

const getters = {
  codigo():number {
    return state.dadosCenarioFiscal.codigo;
  },
  codigoEmpresa():number {
    return state.dadosCenarioFiscal.codigoEmpresa;
  },
  nome():string {
    return state.dadosCenarioFiscal.nome;
  },
};

const mutations = {
  atualizarCodigo(codigo:number):void {
    state.dadosCenarioFiscal.codigo = codigo;
  },
  atualizarCodigoEmpresa(codigo:number):void {
    state.dadosCenarioFiscal.codigoEmpresa = codigo;
  },
  atualizarNome(valor:string):void {
    state.dadosCenarioFiscal.nome = valor;
  },
  atualizarCodigoItemSelecionado(codigo:number):void {
    state.codigoItemSelecionado = codigo;
  },
  atualizarTipoItemSelecionado(tipoItem:ETipoItemCenarioFiscal):void {
    state.tipoItemSelecionado = tipoItem;
  },
  atualizarEstadoSelecionado(estado:IDTOEstado):void {
    state.estadoSelecionado = estado;
  },
  atualizarFiltroApenasDadosEntradas(valor:boolean):void {
    state.filtrarApenasDadosEntradas = valor;
  },
  atualizarFiltroApenasDadosSaidas(valor:boolean):void {
    state.filtrarApenasDadosSaidas = valor;
  },
  atualizarRecarregarResumoIcms(valor:boolean):void {
    state.recarregarResumoIcms = valor;
  },
  atualizarRecarregarResumoIpi(valor:boolean):void {
    state.recarregarResumoIpi = valor;
  },
  atualizarRecarregarResumoPis(valor:boolean):void {
    state.recarregarResumoPis = valor;
  },
  atualizarRecarregarResumoCofins(valor:boolean):void {
    state.recarregarResumoCofins = valor;
  },
};

const actions = {
};

export default {
  state: readonly(state),
  getters,
  mutations,
  actions,
};
