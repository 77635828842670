
import { defineComponent, ref } from 'vue';
import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import CenarioFiscalModal from '@/views/Cadastros/Tributacoes/CenariosFiscais/CenarioFiscalModal.vue';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';

export default defineComponent({
  name: 'CenarioFiscal',
  components: {
    TelaPadraoCrud,
    CenarioFiscalModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();
    const servicoCenarioFiscal = new ServicoCenarioFiscal();

    const refCrudCenarioFiscal = ref<InstanceType<typeof TelaPadraoCrud>>();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.apresentarEmpresas = true;
    crudBase.estrutura.botaoPrincipalAcao = 'Novo';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão do Cenário fiscal:';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'CodigoCenarioFiscal', position: 0, visible: false, ordering: true,
      },
      {
        title: 'Nome', dataIndex: 'nome', key: 'NomeCenarioFiscal', position: 2, visible: true, ordering: true, width: 800, fixed: 'left', align: 'left', ellipsis: true, customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Status', dataIndex: 'ativo', key: 'AtivoCenarioFiscal', position: 3, visible: true, align: 'left', customRenderRow: ECustomRenderRow.TagAtivo,
      },
      {
        title: 'Ações', key: 'acoes', position: 4, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'Codigo', ordem: 'DESC' } as IOrdenacao);

    async function sincronizarRegistro(acao: EPermissaoDados, codigoRegistro: number) {
      if (refCrudCenarioFiscal.value !== undefined) {
        await refCrudCenarioFiscal.value.sincronizarRegistro(acao, codigoRegistro);
      }
    }
    return {
      crudBase,
      servicoCenarioFiscal,
      refCrudCenarioFiscal,
      exibirModal,
      sincronizaTelaCrud,
      sincronizarRegistro,
    };
  },
});
