
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import ColunaGrade from '@/core/components/Tela/ColunaGrade.vue';
import Paginacao from '@/core/components/Tela/Paginacao.vue';
import { useGradeBase } from '@/core/composables/GradeBase';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IDTOCenarioFiscalDefinicaoResumo } from '@/models/DTO/Cadastros/Tributacoes/CenariosFiscais/IDTOCenarioFiscalDefinicaoResumo';
import { ETipoItemCenarioFiscal } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoItemCenarioFiscal';
import storeCenarioFiscal from '@/store/Cadastros/Tributacoes/storeCenarioFiscal';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';
import { ETipoImposto } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoImposto';
import CenarioFiscalDefinicoesCofinsEspecificoModal from './CenarioFiscalDefinicoesCofinsEspecificoModal.vue';

export default defineComponent({
  name: 'CenarioFiscalSelecionarNcmProdutoDefinicaoCofinsModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icone,
    Card,
    MensagemSemDados,
    ColunaGrade,
    Paginacao,
    CenarioFiscalDefinicoesCofinsEspecificoModal,
  },
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const {
      modalBase,
    } = useModalBase(props, emit);
    const { gradeBase } = useGradeBase();
    const servicoCenarioFiscal = new ServicoCenarioFiscal();
    const state = reactive({
      filtrarCodigoNCM: '',
      bloquearEdicao: false,
      definicoesCofinsResumo: [] as IDTOCenarioFiscalDefinicaoResumo[],
      apresentarDefinicoesCofins: false,
    });

    gradeBase.colunas = [
      {
        title: 'Ações', key: 'acoes', position: 1, visible: true, align: 'center', fixed: 'left', width: 100,
      },
      {
        title: 'Tipo Item', dataIndex: 'tipoItemDescricao', key: 'TipoItemDefinicaoCofins', align: 'left', position: 2, visible: true, width: 80,
      },
      {
        title: 'Código', dataIndex: 'codigo', key: 'CodigoDefinicaoCofins', align: 'left', position: 3, visible: true, ordering: true, width: 150,
      },
      {
        title: 'Descrição', dataIndex: 'descricao', key: 'DescricaoDefinicaoCofins', align: 'left', position: 4, visible: true, ordering: true,
      },
      {
        title: 'Situação', dataIndex: 'situacao', key: 'SituacaoDefinicaoCofins', align: 'left', position: 5, visible: true, ordering: true,
      },
    ];

    async function buscarDados() {
      state.definicoesCofinsResumo = [];

      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.numeroPagina = gradeBase.paginacao.current;
      parametrosConsulta.qtdeRegistrosPagina = gradeBase.paginacao.pageSize;
      parametrosConsulta.qtdeRegistrosTotal = gradeBase.paginacao.total;
      parametrosConsulta.ordenacao = [];

      gradeBase.buscandoDados = true;
      const listaPaginada = await servicoCenarioFiscal.obterResumoDefinicoes(storeCenarioFiscal.getters.codigo(), ETipoImposto.Cofins, parametrosConsulta, state.filtrarCodigoNCM);

      if (listaPaginada !== undefined && listaPaginada !== null) {
        if (UtilitarioGeral.validaLista(listaPaginada.dados)) {
          state.definicoesCofinsResumo = listaPaginada.dados;
          gradeBase.paginacaoApi = listaPaginada.metaData;
        }
      }

      gradeBase.buscandoDados = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        if (storeCenarioFiscal.getters.codigo() > 0) {
          await buscarDados();
        }
      }
      telaBase.carregando = false;
    });

    watch(async () => storeCenarioFiscal.state.recarregarResumoCofins, async () => {
      telaBase.carregando = true;
      if (storeCenarioFiscal.state.recarregarResumoCofins) {
        if (storeCenarioFiscal.getters.codigo() > 0) {
          await buscarDados();
          storeCenarioFiscal.mutations.atualizarRecarregarResumoCofins(false);
        }
      }
      telaBase.carregando = false;
    });

    function preparaMensagemGrade():string {
      if (gradeBase.buscandoDados) {
        return 'Buscando informações, por favor aguarde...';
      }

      if (UtilitarioGeral.valorValido(state.filtrarCodigoNCM) && gradeBase.nenhumRegistroEncontrado) {
        return 'Nenhuma definição tributária de COFINS foi encontrada com o código informado!';
      }

      return 'Adicione a sua definição tributária de COFINS';
    }

    function apresentarNovaDefinicaoTributariaCofins(tipoItem:ETipoItemCenarioFiscal) {
      storeCenarioFiscal.mutations.atualizarCodigoItemSelecionado(0);
      storeCenarioFiscal.mutations.atualizarTipoItemSelecionado(tipoItem);
      state.bloquearEdicao = false;
      state.apresentarDefinicoesCofins = true;
    }

    function apresentarDefinicaoTributariaCofins(definicoesResumo: IDTOCenarioFiscalDefinicaoResumo) {
      if (definicoesResumo.tipoItem === ETipoItemCenarioFiscal.Ncm) {
        storeCenarioFiscal.mutations.atualizarCodigoItemSelecionado(definicoesResumo.codigoNcm);
      } else if (definicoesResumo.tipoItem === ETipoItemCenarioFiscal.Produto) {
        storeCenarioFiscal.mutations.atualizarCodigoItemSelecionado(definicoesResumo.codigoProdutoDefinicao);
      }
      storeCenarioFiscal.mutations.atualizarTipoItemSelecionado(definicoesResumo.tipoItem);
      state.bloquearEdicao = true;
      state.apresentarDefinicoesCofins = true;
    }

    async function pesquisarCargaTributariaPorCodigoNCM() {
      gradeBase.paginacao.current = 1;
      await buscarDados();
    }

    return {
      telaBase,
      modalBase,
      gradeBase,
      props,
      state,
      ETipoItemCenarioFiscal,
      preparaMensagemGrade,
      pesquisarCargaTributariaPorCodigoNCM,
      buscarDados,
      apresentarNovaDefinicaoTributariaCofins,
      apresentarDefinicaoTributariaCofins,
    };
  },
});
