import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  class: "ss-titulo-estados",
  style: {"margin-bottom":"0px"}
}
const _hoisted_5 = { style: {"margin-bottom":"10px"} }
const _hoisted_6 = { style: {"color":"#818181"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_selecionar_ncm = _resolveComponent("selecionar-ncm")!
  const _component_card = _resolveComponent("card")!
  const _component_selecionar_produto = _resolveComponent("selecionar-produto")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_cenario_fiscal_definicoes_icms_estado_especifico_modal = _resolveComponent("cenario-fiscal-definicoes-icms-estado-especifico-modal")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      title: "Definições de ICMS/CST/CFOP",
      centered: "",
      width: "100%",
      "wrap-class-name": "full-modal ss-modal-tributacoes",
      visible: _ctx.modalBase.computedVisivel,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
      footer: null
    }, {
      closeIcon: _withCtx(() => [
        _createVNode(_component_icone, { nome: "voltar" })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_form, { layout: "vertical" }, {
          default: _withCtx(() => [
            ((_ctx.storeCenarioFiscal.state.tipoItemSelecionado === _ctx.ETipoItemCenarioFiscal.Ncm))
              ? (_openBlock(), _createBlock(_component_card, {
                  key: 0,
                  titulo: "NCM"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_selecionar_ncm, {
                      codigoSelecionado: _ctx.state.codigoItemSelecionado,
                      "onUpdate:codigoSelecionado": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.codigoItemSelecionado) = $event)),
                      onChange: _ctx.obterDefinicoesTributarias,
                      disabled: _ctx.props.bloquearEdicao
                    }, null, 8, ["codigoSelecionado", "onChange", "disabled"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            ((_ctx.storeCenarioFiscal.state.tipoItemSelecionado === _ctx.ETipoItemCenarioFiscal.Produto))
              ? (_openBlock(), _createBlock(_component_card, {
                  key: 1,
                  titulo: "PRODUTO"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_selecionar_produto, {
                      codigoSelecionado: _ctx.state.codigoItemSelecionado,
                      "onUpdate:codigoSelecionado": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.codigoItemSelecionado) = $event)),
                      modoDefinicaoProduto: true,
                      empresas: [_ctx.storeCenarioFiscal.getters.codigoEmpresa()],
                      apresentarCodigoInterno: true,
                      placeholder: 'Digite o código interno, código de barras, código personalizado ou descrição do produto...',
                      limparSelecao: false,
                      disabled: _ctx.props.bloquearEdicao,
                      onChange: _ctx.obterDefinicoesTributarias
                    }, null, 8, ["codigoSelecionado", "empresas", "placeholder", "disabled", "onChange"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_card, { titulo: "CLIQUE NO ESTADO DESEJADO" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_skeleton, {
                  loading: _ctx.telaBase.carregando,
                  paragraph: { rows: 10 }
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.estadosEstrutura, (estado) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: estado.codigo,
                          class: "ant-col ant-col-xs-24 ant-col-xl-6"
                        }, [
                          (_openBlock(), _createElementBlock("a", {
                            key: estado.codigo,
                            onClick: ($event: any) => (_ctx.apresentarModalDefinicoesIcmsEstado(estado))
                          }, [
                            _createElementVNode("img", {
                              class: "ss-bandeira-estado",
                              src: '/static/img/estados/' + estado.sigla.toUpperCase() + '.png'
                            }, null, 8, _hoisted_3),
                            _createElementVNode("span", _hoisted_4, [
                              _createElementVNode("b", null, _toDisplayString(estado.sigla), 1),
                              _createTextVNode(" - " + _toDisplayString(estado.estado), 1)
                            ]),
                            _createElementVNode("div", _hoisted_5, [
                              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.montaApresentacaoDefinicoesPorEstado(estado.codigo)), 1)
                            ])
                          ], 8, _hoisted_2))
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_cenario_fiscal_definicoes_icms_estado_especifico_modal, {
          visivel: _ctx.state.apresentarModalDefinicoesIcmsEstado,
          "onUpdate:visivel": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.apresentarModalDefinicoesIcmsEstado) = $event))
        }, null, 8, ["visivel"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}