
import { defineComponent, reactive, watch } from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarNcm from '@/components/Cadastros/Produtos/SelecionarNcm.vue';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import SelecionarOrigemMercadoria from '../SelecionarOrigemMercadoria.vue';
import SelecionarTipoAtividade from '@/components/Compartilhados/SelecionarTipoAtividade.vue';
import SelecionarTipoCalculoImposto from '../SelecionarTipoCalculoImposto.vue';
import SelecionarCstIpi from '../SelecionarCstIpi.vue';
import SelecionarCodigoEnquadramentoIpi from '../SelecionarCodigoEnquadramentoIpi.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import {
  ICenarioFiscalDefinicao, ICenarioFiscalDefinicaoIpi, ICenarioFiscalDefinicaoIpiTipoAtividade,
} from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';
import { ETipoItemCenarioFiscal } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoItemCenarioFiscal';
import { ETipoImposto } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoImposto';

import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeCenarioFiscal from '@/store/Cadastros/Tributacoes/storeCenarioFiscal';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';

export default defineComponent({
  name: 'CenarioFiscalDefinicaoIpiModal',
  props: {
    visivel: {
      type: Boolean,
    },
    codigoDefinicao: {
      type: Number,
      required: true,
    },
    duplicar: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icone,
    Card,
    CampoNumerico,
    SelecionarSimNao,
    RequisicaoModal,
    SelecionarNcm,
    SelecionarProduto,
    SelecionarOrigemMercadoria,
    SelecionarTipoAtividade,
    SelecionarCstIpi,
    SelecionarTipoCalculoImposto,
    SelecionarCodigoEnquadramentoIpi,
  },
  emits: ['atualizarDefinicao', 'excluirDefinicao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarBarraProgresso, ocultarBarraProgresso, apresentarRetornoRequisicao,
    } = useModalBase(props, emit);
    const servicoCenarioFiscal = new ServicoCenarioFiscal();
    const servicoSistema = new ServicoSistema();

    const state = reactive({
      definicaoTributaria: {} as ICenarioFiscalDefinicao,
      apresentarSelecaoNcmProdutoDefinicaoIpi: false,
      tiposAtividade: [] as number[],
    });

    function objetoInicial() {
      state.definicaoTributaria = {} as ICenarioFiscalDefinicao;
      state.definicaoTributaria.codigo = 0;
      state.definicaoTributaria.codigoCenarioFiscal = storeCenarioFiscal.getters.codigo();
      state.definicaoTributaria.tipoImposto = ETipoImposto.Ipi;
      state.definicaoTributaria.tipoItem = storeCenarioFiscal.state.tipoItemSelecionado;
      if (storeCenarioFiscal.state.tipoItemSelecionado === ETipoItemCenarioFiscal.Ncm) {
        state.definicaoTributaria.codigoNcm = storeCenarioFiscal.state.codigoItemSelecionado;
      } else if (storeCenarioFiscal.state.tipoItemSelecionado === ETipoItemCenarioFiscal.Produto) {
        state.definicaoTributaria.codigoProdutoDefinicao = storeCenarioFiscal.state.codigoItemSelecionado;
      }
      state.definicaoTributaria.definicaoIpi = {} as ICenarioFiscalDefinicaoIpi;
      state.definicaoTributaria.definicaoIpi.tiposAtividade = [];
      state.tiposAtividade = [];
      state.definicaoTributaria.definicaoIpi.especificarTipoAtividade = false;
    }

    objetoInicial();

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (UtilitarioGeral.validaCodigo(props.codigoDefinicao)) {
          state.definicaoTributaria = await servicoCenarioFiscal.obterDefinicao(props.codigoDefinicao, storeCenarioFiscal.getters.codigoEmpresa());

          if (props.duplicar) {
            state.definicaoTributaria.codigo = 0;
            state.definicaoTributaria.definicaoIpi.codigo = 0;
            state.definicaoTributaria.definicaoIpi.codigoCenarioFiscalDefinicao = 0;
            if (UtilitarioGeral.validaLista(state.definicaoTributaria.definicaoIpi.tiposAtividade)) {
              for (let index = 0; index < state.definicaoTributaria.definicaoIpi.tiposAtividade.length; index += 1) {
                state.definicaoTributaria.definicaoIpi.tiposAtividade[index].codigo = 0;
                state.definicaoTributaria.definicaoIpi.tiposAtividade[index].codigoCenarioFiscalDefinicaoIpi = 0;
              }
            }
          }

          if (UtilitarioGeral.validaLista(state.definicaoTributaria.definicaoIpi.tiposAtividade)) {
            state.definicaoTributaria.definicaoIpi.tiposAtividade.forEach((item) => {
              state.tiposAtividade.push(item.tipoAtividade);
            });
          }
        }
      }
      telaBase.carregando = false;
    });

    function preparaPersistenciaTiposAtividades() {
      const tiposAtividades : ICenarioFiscalDefinicaoIpiTipoAtividade[] = [];
      if (state.tiposAtividade.length > 0) {
        state.tiposAtividade.forEach((tipoAtividade) => {
          const tipoAtividadeExistente = state.definicaoTributaria.definicaoIpi.tiposAtividade.find((c) => c.tipoAtividade === tipoAtividade);
          if (tipoAtividadeExistente !== undefined) {
            tiposAtividades.push(tipoAtividadeExistente);
          } else {
            const definicaoIpiTipoAtividade: ICenarioFiscalDefinicaoIpiTipoAtividade = { codigo: 0, codigoCenarioFiscalDefinicaoIpi: state.definicaoTributaria.definicaoIpi.codigo, tipoAtividade };
            tiposAtividades.push(definicaoIpiTipoAtividade);
          }
        });
      }
      state.definicaoTributaria.definicaoIpi.tiposAtividade = tiposAtividades;
    }

    function adicionarVariavelInformacaoComplementar(texto:string) {
      state.definicaoTributaria.definicaoIpi.informacaoComplementar = servicoSistema.montaTextoComVariavelConteudo(state.definicaoTributaria.definicaoIpi.informacaoComplementar, texto);
    }

    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso();
      preparaPersistenciaTiposAtividades();

      if (state.definicaoTributaria.codigo === 0) {
        retorno = await servicoCenarioFiscal.incluirDefinicao(storeCenarioFiscal.getters.codigoEmpresa(), state.definicaoTributaria);
      } else {
        retorno = await servicoCenarioFiscal.alterarDefinicao(state.definicaoTributaria.codigo, storeCenarioFiscal.getters.codigoEmpresa(), state.definicaoTributaria);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (state.definicaoTributaria.codigo === 0) {
          state.definicaoTributaria.codigo = retorno.codigoRegistro;
        }
        storeCenarioFiscal.mutations.atualizarRecarregarResumoIpi(true);
        emit('atualizarDefinicao', state.definicaoTributaria);
        apresentarMensagemSucesso(retorno.mensagem);
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function excluirDefinicaoIpi(codigo: number) {
      const retorno = await servicoCenarioFiscal.excluirDefinicao(storeCenarioFiscal.getters.codigoEmpresa(), [codigo]);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        emit('excluirDefinicao', state.definicaoTributaria);
        modalBase.computedVisivel = false;
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        Modal.warning({
          title: 'Não foi possível concluir a exclusão!',
          content: retorno.mensagem,
        });
      }
    }

    async function confirmaExclusao() {
      Modal.confirm({
        title: 'Atenção',
        content: 'Você confirma a exclusão dessa definição tributária de IPI?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await excluirDefinicaoIpi(state.definicaoTributaria.codigo); },
      });
    }

    return {
      telaBase,
      props,
      modalBase,
      state,
      ETipoItemCenarioFiscal,
      storeCenarioFiscal,
      UtilitarioGeral,
      adicionarVariavelInformacaoComplementar,
      salvar,
      confirmaExclusao,
    };
  },
});
