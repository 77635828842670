
import { computed, defineComponent, reactive } from 'vue';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoCfop from '@/servicos/Cadastros/Tributacoes/ServicoCfop';

export default defineComponent({
  name: 'ApresentarCfop',
  props: {
    codigo: {
      type: Number || undefined,
    },
    apenasCodigoCfop: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigo'],
  setup(props, { emit }) {
    const servicoCfop = new ServicoCfop();

    const state = reactive({
      nomeCfop: '',
    });

    async function obterCfopPorCodigo(valor: any) {
      if (UtilitarioGeral.validaCodigo(valor)) {
        state.nomeCfop = '';
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          apenasAtivos: false, recursoAssociado: '',
        };

        parametrosConsultaRapida.filtrarPorCodigo = true;
        parametrosConsultaRapida.valor = valor;

        const listaCfops = await servicoCfop.consultaRapida(parametrosConsultaRapida);
        if (UtilitarioGeral.validaLista(listaCfops)) {
          state.nomeCfop = props.apenasCodigoCfop ? listaCfops[0].informacaoAdicional : listaCfops[0].textoIdentificacao;
        }
      }
    }
    function obterCfop():any {
      obterCfopPorCodigo(props.codigo);
      return props.codigo;
    }

    const computedCodigo = computed({
      get: () => obterCfop(),
      set: (val: number) => {
        emit('update:codigo', val);
      },
    });

    return {
      props,
      state,
      computedCodigo,
    };
  },
});
