
import {
  defineComponent, reactive, watch, onBeforeMount,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import ColunaGrade from '@/core/components/Tela/ColunaGrade.vue';
import SelecionarNcm from '@/components/Cadastros/Produtos/SelecionarNcm.vue';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import CenarioFiscalDefinicaoCofinsModal from './CenarioFiscalDefinicaoCofinsModal.vue';
import CenarioFiscalCopiarDefinicaoTributariaModal from '../CenarioFiscalCopiarDefinicaoTributariaModal.vue';
import { useGradeBase } from '@/core/composables/GradeBase';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoItemCenarioFiscal } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoItemCenarioFiscal';
import storeCenarioFiscal from '@/store/Cadastros/Tributacoes/storeCenarioFiscal';
import { ICenarioFiscalDefinicao } from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import { IParametrosConsultaDefinicao } from '@/models/ParametrosRequisicao/Cadastros/Tributacoes/IParametrosConsultaDefinicao';
import { ETipoImposto } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoImposto';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import ServicoPessoa from '@/servicos/Cadastros/Pessoas/ServicoPessoa';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';

export default defineComponent({
  name: 'CenarioFiscalDefinicoesCofinsEspecificoModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    bloquearEdicao: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icone,
    Card,
    MensagemSemDados,
    ColunaGrade,
    SelecionarNcm,
    SelecionarProduto,
    CenarioFiscalDefinicaoCofinsModal,
    CenarioFiscalCopiarDefinicaoTributariaModal,
  },
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso } = useTelaBase();
    const {
      modalBase,
    } = useModalBase(props, emit);
    const { gradeBase } = useGradeBase();
    const servicoCenarioFiscal = new ServicoCenarioFiscal();
    const servicoPessoa = new ServicoPessoa();

    const state = reactive({
      tipoItemSelecionado: {} as ETipoItemCenarioFiscal,
      codigoItemSelecionado: 0,
      bloquearEdicao: false,
      definicoes: [] as ICenarioFiscalDefinicao[],
      definicoesSelecionadas: [] as ICenarioFiscalDefinicao[],
      origensMercadorias: [] as IItemGenerico[],
      tiposAtividades: [] as IItemGenerico[],
      apresentarDefinicoesCofins: false,
      codigoDefinicao: 0,
      duplicarDefinicao: false,
      apresentarCopiarDefinicoes: false,
    });

    onBeforeMount(async () => {
      state.origensMercadorias = await servicoCenarioFiscal.obterListaOrigemMercadorias();
      state.tiposAtividades = await servicoPessoa.obterListaTipoAtividade();
    });

    gradeBase.colunas = [
      {
        title: 'Ações', key: 'acoes', position: 1, visible: true, align: 'left', fixed: 'left', width: 100,
      },
      {
        title: 'CST', dataIndex: 'cstCofins', key: 'CstCofins', align: 'center', position: 2, visible: true, width: 100,
      },
      {
        title: 'Alíquota %', dataIndex: 'aliquotaCofins', key: 'AliquotaCofins', align: 'right', position: 3, visible: true, width: 150,
      },
      {
        title: 'Red.Base %', dataIndex: 'reducaoBaseCalculoCofins', key: 'ReducaoBaseCalculoCofins', align: 'right', position: 4, visible: true, width: 150,
      },
      {
        title: 'Alíquota ST %', dataIndex: 'aliquotaCofinsSubstituicao', key: 'AliquotaCofinsSubstituicao', align: 'right', position: 5, visible: true, width: 150,
      },
      {
        title: 'Vl. Mínimo por Unidade', dataIndex: 'valorUnidadeTributavelCofins', key: 'ValorUnidadeTributavelCofins', align: 'right', position: 6, visible: true, width: 250,
      },
      {
        title: 'Código', dataIndex: 'codigo', key: 'CodigoDefinicao', position: 7, visible: false, ordering: true,
      },
    ];

    async function buscarDados() {
      state.definicoes = [];

      const parametrosConsulta = {} as IParametrosConsultaDefinicao;
      parametrosConsulta.tipoImposto = ETipoImposto.Cofins;
      parametrosConsulta.tipoItem = storeCenarioFiscal.state.tipoItemSelecionado;
      parametrosConsulta.codigoItemSelecionado = storeCenarioFiscal.state.codigoItemSelecionado;
      parametrosConsulta.estado = 0;
      parametrosConsulta.buscaResumida = false;

      gradeBase.buscandoDados = true;

      const listaDefinicoes = await servicoCenarioFiscal.obterDefinicoes(storeCenarioFiscal.getters.codigo(), parametrosConsulta);
      if (listaDefinicoes !== undefined && listaDefinicoes !== null) {
        state.definicoes = listaDefinicoes;
      }

      gradeBase.buscandoDados = false;
    }

    async function itemAlterado() {
      storeCenarioFiscal.mutations.atualizarCodigoItemSelecionado(state.codigoItemSelecionado);
      await buscarDados();
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      state.definicoes = [];
      if (modalBase.computedVisivel) {
        if (UtilitarioGeral.validaCodigo(storeCenarioFiscal.state.codigoItemSelecionado)) {
          state.codigoItemSelecionado = storeCenarioFiscal.state.codigoItemSelecionado;
        } else {
          state.codigoItemSelecionado = 0;
        }

        if (storeCenarioFiscal.state.codigoItemSelecionado > 0) {
          await buscarDados();
        }
      }
      telaBase.carregando = false;
    });

    function preparaMensagemGrade():string {
      if (gradeBase.buscandoDados) {
        return 'Buscando informações, por favor aguarde...';
      }

      return 'Adicione a sua definição tributária de COFINS';
    }

    function apresentarModalDefinicaoCofins(codigoDefinicao: number, duplicar:boolean) {
      if (UtilitarioGeral.validaCodigo(state.codigoItemSelecionado)) {
        state.codigoDefinicao = codigoDefinicao;
        state.duplicarDefinicao = duplicar;
        storeCenarioFiscal.mutations.atualizarCodigoItemSelecionado(state.codigoItemSelecionado);
        state.apresentarDefinicoesCofins = true;
      } else {
        apresentarMensagemAlerta('É necessário selecionar um item antes!');
      }
    }

    function atualizarDefinicao(definicao: ICenarioFiscalDefinicao) {
      const index = state.definicoes.findIndex((c) => c.codigo === definicao.codigo);
      if (index >= 0) {
        state.definicoes[index] = definicao;
      } else {
        state.definicoes.push(definicao);
      }
    }

    function excluirDefinicao(definicao: ICenarioFiscalDefinicao) {
      const index = state.definicoes.findIndex((c) => c.codigo === definicao.codigo);
      if (index >= 0) {
        state.definicoes.splice(index, 1);
      }
    }

    const onSelectChange = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
    };

    function copiarDefinicaoTributaria() {
      state.definicoesSelecionadas = [];
      if (!UtilitarioGeral.validaLista(gradeBase.codigosSelecionados)) {
        apresentarMensagemAlerta('Nenhuma definição foi selecionada!');
        return;
      }

      const definicoesSelecionadas:ICenarioFiscalDefinicao[] = [];

      gradeBase.codigosSelecionados.forEach((codigoDefinicao) => {
        const definicaoTributaria = state.definicoes.find((c) => c.codigo === codigoDefinicao);
        if (definicaoTributaria !== undefined) {
          definicoesSelecionadas.push(definicaoTributaria);
        }
      });

      state.definicoesSelecionadas = definicoesSelecionadas;
      state.apresentarCopiarDefinicoes = true;
    }

    async function excluirDefinicoes(codigosDefinicoes:number[]) {
      const retorno = await servicoCenarioFiscal.excluirDefinicao(storeCenarioFiscal.getters.codigoEmpresa(), codigosDefinicoes);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        codigosDefinicoes.forEach((codigoDefinicao) => {
          const index = state.definicoes.findIndex((c) => c.codigo === codigoDefinicao);
          if (index >= 0) {
            state.definicoes.splice(index, 1);
          }
        });
        apresentarMensagemSucesso(retorno.mensagem);
        storeCenarioFiscal.mutations.atualizarRecarregarResumoCofins(true);
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta('Não foi possível concluir a exclusão!');
      }
    }

    async function confirmaExclusao(codigosDefinicoes:number[]) {
      Modal.confirm({
        title: 'Atenção',
        content: codigosDefinicoes.length === 1 ? 'Você confirma a exclusão dessa definição tributária de COFINS?' : 'Você confirma a exclusão dessas definições tributárias de COFINS?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await excluirDefinicoes(codigosDefinicoes); },
      });
    }

    return {
      telaBase,
      modalBase,
      gradeBase,
      props,
      state,
      ETipoItemCenarioFiscal,
      ETipoImposto,
      storeCenarioFiscal,
      onSelectChange,
      UtilitarioMascara,
      preparaMensagemGrade,
      apresentarModalDefinicaoCofins,
      buscarDados,
      atualizarDefinicao,
      excluirDefinicao,
      copiarDefinicaoTributaria,
      confirmaExclusao,
      itemAlterado,
    };
  },
});
