import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c85b358"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ss-botao-informativo" }
const _hoisted_2 = { class: "ss-botao-informativo-titulo" }
const _hoisted_3 = { class: "ss-botao-informativo-fundo-informacoes" }
const _hoisted_4 = { class: "ss-botao-informativo-informacoes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.props.titulo), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.props.informacoes), 1)
      ])
    ])
  ]))
}