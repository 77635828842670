
import {
  defineComponent, reactive, watch, onBeforeMount,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import BotaoInformativo from '@/core/components/Tela/BotaoInformativo.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarTipoMovimentoComercial from '@/components/Compartilhados/SelecionarTipoMovimentoComercial.vue';
import CenarioFiscalSelecionarNcmProdutoDefinicaoIcmsModal from '@/components/Cadastros/Tributacoes/CenariosFiscais/DefinicaoIcms/CenarioFiscalSelecionarNcmProdutoDefinicaoIcmsModal.vue';
import CenarioFiscalSelecionarNcmProdutoDefinicaoIpiModal from '@/components/Cadastros/Tributacoes/CenariosFiscais/DefinicaoIpi/CenarioFiscalSelecionarNcmProdutoDefinicaoIpiModal.vue';
import CenarioFiscalDefinicaoPisPadraoModal from '@/components/Cadastros/Tributacoes/CenariosFiscais/DefinicaoPis/CenarioFiscalDefinicaoPisPadraoModal.vue';
import CenarioFiscalDefinicaoCofinsPadraoModal from '@/components/Cadastros/Tributacoes/CenariosFiscais/DefinicaoCofins/CenarioFiscalDefinicaoCofinsPadraoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ICenarioFiscal, ICenarioFiscalTipoMovimentacao } from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';
import storeCenarioFiscal from '@/store/Cadastros/Tributacoes/storeCenarioFiscal';
import ServicoEmpresa from '@/servicos/MeuSistema/ServicoEmpresa';
import { IEmpresa } from '@/models/Entidades/MeuSistema/Empresas/IEmpresa';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import storeSistema from '@/store/storeSistema';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';

export default defineComponent({
  name: 'CenarioFiscalModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    BotaoInformativo,
    RequisicaoModal,
    CenarioFiscalSelecionarNcmProdutoDefinicaoIcmsModal,
    CenarioFiscalSelecionarNcmProdutoDefinicaoIpiModal,
    SelecionarEmpresa,
    SelecionarTipoMovimentoComercial,
    CenarioFiscalDefinicaoPisPadraoModal,
    CenarioFiscalDefinicaoCofinsPadraoModal,
    PreviewPdf,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemAlerta, apresentarMensagemErro, obterPermissoes, filtrarPermissaoDadosUsuario, preencherPermissoesDados, apresentarMensagemSucesso, preencherEmpresasComEstrategiaPermissaoDados,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    telaBase.identificadorRecurso = 'CADASTRO_CENARIO_FISCAL';
    telaBase.identificadorPermissao = 'PER_CADASTRO_CENARIO_FISCAL';

    const servicoCenarioFiscal = new ServicoCenarioFiscal();
    const servicoEmpresa = new ServicoEmpresa();
    const servicoSistema = new ServicoSistema();
    const state = reactive({
      cenarioFiscal: {} as ICenarioFiscal,
      empresa: {} as IEmpresa,
      apresentarSelecaoNcmProdutoDefinicaoIcms: false,
      apresentarSelecaoNcmProdutoDefinicaoIpi: false,
      apresentarDefinicaoPadraoPis: false,
      apresentarDefinicaoPadraoCofins: false,
      regimesTributarios: [] as IItemGenerico[],
      tiposMovimentos: [] as number[],
      arquivosPdf: [] as IArquivoPdf[],
    });

    onBeforeMount(async () => {
      state.regimesTributarios = await servicoEmpresa.obterListaRegimeTributario();
    });

    function apresentarRegimeTributario(regimeTributario: number): string {
      if (UtilitarioGeral.validaLista(state.regimesTributarios)) {
        const dadosRegimeTributario = state.regimesTributarios.find((c) => c.identificador === regimeTributario.toString());
        if (dadosRegimeTributario !== undefined) {
          return dadosRegimeTributario.descricao;
        }
      }
      return '';
    }

    function objetoInicial() {
      state.empresa = {} as IEmpresa;
      state.cenarioFiscal = {} as ICenarioFiscal;
      state.cenarioFiscal.tiposMovimento = [];
      state.cenarioFiscal.codigo = 0;
      state.cenarioFiscal.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      state.cenarioFiscal.nome = '';
      state.cenarioFiscal.ativo = true;
      state.tiposMovimentos = [];
    }

    async function preencherDadosEmpresa() {
      state.empresa = await servicoEmpresa.obter(state.cenarioFiscal.codigoEmpresa);
    }

    function preparaPersistenciaTiposMovimentos() {
      const cenarioFiscalTiposMovimentos: ICenarioFiscalTipoMovimentacao[] = [];
      if (state.tiposMovimentos.length > 0) {
        state.tiposMovimentos.forEach((tipoMovimentacao) => {
          const tipoMovimentoExistente = state.cenarioFiscal.tiposMovimento.find((c) => c.tipoMovimentacao === tipoMovimentacao);
          if (tipoMovimentoExistente !== undefined) {
            cenarioFiscalTiposMovimentos.push(tipoMovimentoExistente);
          } else {
            const cenarioFiscalTipoMovimento: ICenarioFiscalTipoMovimentacao = { codigo: 0, codigoCenarioFiscal: state.cenarioFiscal.codigo, tipoMovimentacao };
            cenarioFiscalTiposMovimentos.push(cenarioFiscalTipoMovimento);
          }
        });
      }
      state.cenarioFiscal.tiposMovimento = cenarioFiscalTiposMovimentos;
    }

    function alteracaoNome() {
      storeCenarioFiscal.mutations.atualizarNome(state.cenarioFiscal.nome);
    }

    function adicionarVariavelInformacaoComplementar(texto: string) {
      state.cenarioFiscal.informacaoComplementar = servicoSistema.montaTextoComVariavelConteudo(state.cenarioFiscal.informacaoComplementar, texto);
    }

    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso();
      preparaPersistenciaTiposMovimentos();

      if (state.cenarioFiscal.codigo === 0) {
        retorno = await servicoCenarioFiscal.incluir(state.cenarioFiscal);
      } else {
        retorno = await servicoCenarioFiscal.alterar(state.cenarioFiscal);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
          state.cenarioFiscal.codigo = retorno.codigoRegistro;
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        storeCenarioFiscal.mutations.atualizarCodigo(state.cenarioFiscal.codigo);
        storeCenarioFiscal.mutations.atualizarCodigoEmpresa(state.cenarioFiscal.codigoEmpresa);
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    function atualizarFiltrosBaseadosMovimentoComercial() {
      if (UtilitarioGeral.validaLista(state.tiposMovimentos)) {
        storeCenarioFiscal.mutations.atualizarFiltroApenasDadosEntradas(servicoSistema.verificaMovimentosComerciaisEntrada(state.tiposMovimentos));
        storeCenarioFiscal.mutations.atualizarFiltroApenasDadosSaidas(servicoSistema.verificaMovimentosComerciaisSaida(state.tiposMovimentos));
      } else {
        storeCenarioFiscal.mutations.atualizarFiltroApenasDadosEntradas(false);
        storeCenarioFiscal.mutations.atualizarFiltroApenasDadosSaidas(false);
      }
    }

    function alteracoesMovimentosComerciais() {
      atualizarFiltrosBaseadosMovimentoComercial();
    }
    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, false);
        telaBase.permissaoDados = filtrarPermissaoDadosUsuario(props.operacao.empresaSelecionada);

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.cenarioFiscal = await servicoCenarioFiscal.obter(props.operacao.codigoRegistro);
          await preencherDadosEmpresa();
          if (UtilitarioGeral.validaLista(state.cenarioFiscal.tiposMovimento)) {
            state.cenarioFiscal.tiposMovimento.forEach((item) => {
              state.tiposMovimentos.push(item.tipoMovimentacao);
            });
          }
        }
        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
      }

      storeCenarioFiscal.mutations.atualizarCodigo(state.cenarioFiscal.codigo);
      storeCenarioFiscal.mutations.atualizarCodigoEmpresa(state.cenarioFiscal.codigoEmpresa);
      storeCenarioFiscal.mutations.atualizarNome(state.cenarioFiscal.nome);
      atualizarFiltrosBaseadosMovimentoComercial();
      telaBase.carregando = false;
    });

    async function relatorioDetalhado() {
      telaBase.carregando = true;
      const retornoRelatorio = await servicoCenarioFiscal.relatorioDetalhado(state.cenarioFiscal.codigo);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }
    return {
      telaBase,
      props,
      modalBase,
      state,
      storeCenarioFiscal,
      EPermissaoDados,
      UtilitarioGeral,
      UtilitarioMascara,
      salvar,
      objetoInicial,
      preencherDadosEmpresa,
      apresentarRegimeTributario,
      alteracaoNome,
      adicionarVariavelInformacaoComplementar,
      alteracoesMovimentosComerciais,
      relatorioDetalhado,
    };
  },
});
