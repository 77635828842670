
import {
  defineComponent, reactive, watch, onBeforeMount,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarNcm from '@/components/Cadastros/Produtos/SelecionarNcm.vue';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import { useGradeBase } from '@/core/composables/GradeBase';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ETipoItemCenarioFiscal } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoItemCenarioFiscal';
import { IDTOEstado } from '@/models/DTO/Cadastros/Localizacoes/IDTOEstado';
import ServicoEstado from '@/servicos/Cadastros/Localizacoes/ServicoEstado';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import CenarioFiscalDefinicoesIcmsEstadoEspecificoModal from './CenarioFiscalDefinicoesIcmsEstadoEspecificoModal.vue';
import storeCenarioFiscal from '@/store/Cadastros/Tributacoes/storeCenarioFiscal';
import { ICenarioFiscalDefinicao } from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';
import { IParametrosConsultaDefinicao } from '@/models/ParametrosRequisicao/Cadastros/Tributacoes/IParametrosConsultaDefinicao';
import { ETipoImposto } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoImposto';

export default defineComponent({
  name: 'CenarioFiscalDefinicoesIcmsEstadosModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    bloquearEdicao: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icone,
    Card,
    SelecionarNcm,
    SelecionarProduto,
    CenarioFiscalDefinicoesIcmsEstadoEspecificoModal,
  },
  emits: ['update:codigoItemSelecionado'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const {
      modalBase,
    } = useModalBase(props, emit);
    const { gradeBase } = useGradeBase();

    const servicoEstado = new ServicoEstado();
    const servicoCenarioFiscal = new ServicoCenarioFiscal();

    const state = reactive({
      filtrarCodigoNCM: '',
      apresentarModalDefinicoesIcmsEstado: false,
      codigoSelecionado: '',
      estadosEstrutura: [] as IDTOEstado[],
      codigoItemSelecionado: 0,
      definicoesTributarias: [] as ICenarioFiscalDefinicao[],
    });

    async function obterDefinicoesTributarias() {
      state.definicoesTributarias = [];
      const parametrosConsulta = {} as IParametrosConsultaDefinicao;
      parametrosConsulta.tipoImposto = ETipoImposto.Icms;
      storeCenarioFiscal.mutations.atualizarCodigoItemSelecionado(state.codigoItemSelecionado);
      parametrosConsulta.tipoItem = storeCenarioFiscal.state.tipoItemSelecionado;
      parametrosConsulta.codigoItemSelecionado = storeCenarioFiscal.state.codigoItemSelecionado;
      parametrosConsulta.estado = 0;
      parametrosConsulta.buscaResumida = true;

      const listaDefinicoes = await servicoCenarioFiscal.obterDefinicoes(storeCenarioFiscal.getters.codigo(), parametrosConsulta);
      if (UtilitarioGeral.validaLista(listaDefinicoes)) {
        state.definicoesTributarias = listaDefinicoes;
      }
    }
    onBeforeMount(async () => {
      state.estadosEstrutura = await servicoEstado.obterTodos();
    });

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      state.definicoesTributarias = [];
      if (modalBase.computedVisivel) {
        if (UtilitarioGeral.validaCodigo(storeCenarioFiscal.state.codigoItemSelecionado)) {
          state.codigoItemSelecionado = storeCenarioFiscal.state.codigoItemSelecionado;
        } else {
          state.codigoItemSelecionado = 0;
        }

        if (UtilitarioGeral.validaCodigo(storeCenarioFiscal.state.codigoItemSelecionado)) {
          await obterDefinicoesTributarias();
        }
      }
      telaBase.carregando = false;
    });

    watch(async () => state.apresentarModalDefinicoesIcmsEstado, async () => {
      telaBase.carregando = true;
      if (!state.apresentarModalDefinicoesIcmsEstado) {
        if (UtilitarioGeral.validaCodigo(storeCenarioFiscal.state.codigoItemSelecionado)) {
          await obterDefinicoesTributarias();
        }
      }
      telaBase.carregando = false;
    });

    function apresentarModalDefinicoesIcmsEstado(estado:IDTOEstado) {
      if (UtilitarioGeral.validaCodigo(state.codigoItemSelecionado)) {
        storeCenarioFiscal.mutations.atualizarEstadoSelecionado(estado);
        storeCenarioFiscal.mutations.atualizarCodigoItemSelecionado(state.codigoItemSelecionado);
        state.apresentarModalDefinicoesIcmsEstado = true;
      } else {
        let tipoItem = 'item';
        if (storeCenarioFiscal.state.tipoItemSelecionado === ETipoItemCenarioFiscal.Ncm) {
          tipoItem = 'NCM';
        } else if (storeCenarioFiscal.state.tipoItemSelecionado === ETipoItemCenarioFiscal.Produto) {
          tipoItem = 'Produto';
        }
        apresentarMensagemAlerta(`Selecione um ${tipoItem} para prosseguir!`);
      }
    }

    function montaApresentacaoDefinicoesPorEstado(codigoEstado:number) {
      if (UtilitarioGeral.validaLista(state.definicoesTributarias)) {
        const definicoesEstado = state.definicoesTributarias.filter((c) => c.codigoEstado === codigoEstado);
        if (UtilitarioGeral.validaLista(definicoesEstado)) {
          if (definicoesEstado.length === 1) {
            return '1 - Definição';
          }
          return `${definicoesEstado.length} - Definições`;
        }
      }
      return 'Nenhuma definição';
    }

    return {
      telaBase,
      modalBase,
      gradeBase,
      props,
      state,
      ETipoItemCenarioFiscal,
      storeCenarioFiscal,
      apresentarModalDefinicoesIcmsEstado,
      obterDefinicoesTributarias,
      montaApresentacaoDefinicoesPorEstado,
    };
  },
});
