
import {
  defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import SelecionarNcm from '@/components/Cadastros/Produtos/SelecionarNcm.vue';
import SelecionarProduto from '@/components/Cadastros/Produtos/SelecionarProduto.vue';
import { useGradeBase } from '@/core/composables/GradeBase';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoItemCenarioFiscal } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoItemCenarioFiscal';
import storeCenarioFiscal from '@/store/Cadastros/Tributacoes/storeCenarioFiscal';
import { ICenarioFiscalDefinicao } from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IDTOCenarioFiscalCopiarDefinicoes } from '@/models/DTO/Cadastros/Tributacoes/CenariosFiscais/IDTOCenarioFiscalCopiarDefinicoes';
import { IDTOCenarioFiscalCopiarDefinicoesItem } from '@/models/DTO/Cadastros/Tributacoes/CenariosFiscais/IDTOCenarioFiscalCopiarDefinicoesItem';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import ServicoNcm from '@/servicos/Cadastros/Produtos/ServicoNcm';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoImposto } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoImposto';

export enum ETipoSelecaoItens {
    PesquisaIndividual = 1,
    ListaCodigos = 2,
    TodosNcmsVinculados = 3,
}

export default defineComponent({
  name: 'CenarioFiscalCopiarDefinicaoTributariaModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    tipoImposto: {
      type: Number,
      required: true,
    },
    definicoesTributarias: {
      type: Array as () => ICenarioFiscalDefinicao[],
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    RequisicaoModal,
    MensagemSemDados,
    SelecionarNcm,
    SelecionarProduto,
  },
  emits: ['recarregarDefinicoes', 'update:visivel'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso } = useTelaBase();
    const {
      modalBase, apresentarBarraProgresso, ocultarBarraProgresso, apresentarRetornoRequisicao,
    } = useModalBase(props, emit);
    const { gradeBase } = useGradeBase();

    const servicoCenarioFiscal = new ServicoCenarioFiscal();

    const state = reactive({
      tipoItemSelecionado: {} as ETipoItemCenarioFiscal,
      codigoItemSelecionado: 0,
      listaCodigos: '',
      tipoSelecaoItens: {} as ETipoSelecaoItens,
      definicoes: [] as ICenarioFiscalDefinicao[],
      objeto: {} as IDTOCenarioFiscalCopiarDefinicoes,
    });

    function objetoInicial() {
      state.tipoItemSelecionado = storeCenarioFiscal.state.tipoItemSelecionado;
      state.tipoSelecaoItens = ETipoSelecaoItens.PesquisaIndividual;
      state.objeto = {} as IDTOCenarioFiscalCopiarDefinicoes;
      state.objeto.tipoImposto = props.tipoImposto;
      state.objeto.codigoCenarioFiscal = storeCenarioFiscal.getters.codigo();
      state.objeto.codigoEmpresa = storeCenarioFiscal.getters.codigoEmpresa();
      state.objeto.itensSelecionados = [];
      state.objeto.definicoesTributarias = [];
      if (modalBase.computedVisivel && UtilitarioGeral.validaLista(props.definicoesTributarias)) {
        state.objeto.definicoesTributarias = props.definicoesTributarias;
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      objetoInicial();
    });

    function tipoItemAlterado() {
      state.codigoItemSelecionado = 0;
    }

    function removerItemSelecionado(index:number) {
      state.objeto.itensSelecionados.splice(index, 1);
    }

    function adicionarNovoItem(tipoItemSelecionado:number, codigoItemSelecionado:number) {
      if (UtilitarioGeral.validaLista(state.objeto.itensSelecionados)) {
        const indiceExiste = state.objeto.itensSelecionados.findIndex((c) => c.tipoItem === tipoItemSelecionado && c.codigoSelecionado === codigoItemSelecionado);
        if (indiceExiste >= 0) { return; }
      }

      const itemSelecionado :IDTOCenarioFiscalCopiarDefinicoesItem = {} as IDTOCenarioFiscalCopiarDefinicoesItem;
      itemSelecionado.tipoItem = tipoItemSelecionado;
      itemSelecionado.codigoSelecionado = codigoItemSelecionado;
      state.objeto.itensSelecionados.push(itemSelecionado);
    }

    async function adicionarItemSelecionado() {
      if (state.tipoSelecaoItens === ETipoSelecaoItens.PesquisaIndividual) {
        if (!UtilitarioGeral.validaCodigo(state.codigoItemSelecionado)) {
          apresentarMensagemAlerta('Nenhum item foi selecionado');
          return;
        }

        adicionarNovoItem(state.tipoItemSelecionado, state.codigoItemSelecionado);
        state.codigoItemSelecionado = 0;
      } else if (state.tipoSelecaoItens === ETipoSelecaoItens.ListaCodigos) {
        let listaCodigos = [];
        if (UtilitarioGeral.valorValido(state.listaCodigos)) {
          const codigosInformados = state.listaCodigos.split(/\r?\n/);
          if (UtilitarioGeral.validaLista(codigosInformados)) {
            listaCodigos = codigosInformados;
          } else {
            listaCodigos.push(state.listaCodigos);
          }
          const parametrosConsultaRapida: IParametrosConsultaRapida = {
            valores: listaCodigos,
          };

          if (state.tipoItemSelecionado === ETipoItemCenarioFiscal.Ncm) {
            let listaNcms = await new ServicoNcm().consultaRapida(parametrosConsultaRapida);
            if (storeCenarioFiscal.state.tipoItemSelecionado === ETipoItemCenarioFiscal.Ncm) {
              listaNcms = listaNcms.filter((c) => c.codigo !== storeCenarioFiscal.state.codigoItemSelecionado);
            }

            if (UtilitarioGeral.validaLista(listaNcms)) {
              listaNcms.forEach((itemNcm) => {
                adicionarNovoItem(ETipoItemCenarioFiscal.Ncm, itemNcm.codigo);
              });
            }
          } else if (state.tipoItemSelecionado === ETipoItemCenarioFiscal.Produto) {
            parametrosConsultaRapida.empresas = [storeCenarioFiscal.getters.codigoEmpresa()];
            let listaProdutos = await new ServicoProduto().consultaRapidaDefinicoes(parametrosConsultaRapida);
            if (storeCenarioFiscal.state.tipoItemSelecionado === ETipoItemCenarioFiscal.Produto) {
              listaProdutos = listaProdutos.filter((c) => c.codigo !== storeCenarioFiscal.state.codigoItemSelecionado);
            }

            if (UtilitarioGeral.validaLista(listaProdutos)) {
              listaProdutos.forEach((itemProduto) => {
                adicionarNovoItem(ETipoItemCenarioFiscal.Produto, itemProduto.codigo);
              });
            }
            state.listaCodigos = '';
          }
        } else {
          apresentarMensagemAlerta('Nenhum código foi informado!');
        }
      } else if (state.tipoSelecaoItens === ETipoSelecaoItens.TodosNcmsVinculados) {
        const servicoProduto = new ServicoProduto();
        const listaNcms = await servicoProduto.obterCodigosNcmsVinculadoProdutos([storeCenarioFiscal.getters.codigoEmpresa()]);
        if (UtilitarioGeral.validaLista(listaNcms)) {
          listaNcms.forEach((codigoNcm) => {
            adicionarNovoItem(ETipoItemCenarioFiscal.Ncm, codigoNcm);
          });
        }
      }
    }

    async function concluir() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso('Aguarde por favor... Estamos copiando as suas definições tributárias.');

      retorno = await servicoCenarioFiscal.copiarDefinicoes(state.objeto);

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        switch (props.tipoImposto) {
          case ETipoImposto.Icms:
            storeCenarioFiscal.mutations.atualizarRecarregarResumoIcms(true);
            break;
          case ETipoImposto.Ipi:
            storeCenarioFiscal.mutations.atualizarRecarregarResumoIpi(true);
            break;
          case ETipoImposto.Pis:
            storeCenarioFiscal.mutations.atualizarRecarregarResumoPis(true);
            break;
          case ETipoImposto.Cofins:
            storeCenarioFiscal.mutations.atualizarRecarregarResumoCofins(true);
            break;
          default:
        }
        emit('recarregarDefinicoes');
        apresentarMensagemSucesso(retorno.mensagem);
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    return {
      telaBase,
      modalBase,
      gradeBase,
      props,
      state,
      ETipoItemCenarioFiscal,
      ETipoSelecaoItens,
      storeCenarioFiscal,
      UtilitarioMascara,
      tipoItemAlterado,
      adicionarItemSelecionado,
      removerItemSelecionado,
      concluir,
    };
  },
});
